<template>
  <div
      class="emptyTip"
      v-if="show"
    >
      <p>暂无数据</p>
    </div>
</template>

<script>
export default {
  name: 'EmptyTip',
  props: {
    show: Boolean
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.emptyTip {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: sliver;
  }
</style>
