<template>
  <div class="newsKeywordCloud">
    <Empty :show="apiDataLineChart.length == 0"></Empty>
    <div class="word-cloud" ref="wordCloud"></div>
  </div>
</template>

<script>
import js2wordcloud from "js2wordcloud";
import { EventBus } from "../utils/eventBus.js"
import EmptyTip from "@/components/EmptyTip.vue";

export default {
  name: "NewsKeywordCloud",
  components: {
    EmptyTip
  },
  props: {
    apiDataLineChart: Array,
  },
  data() {
    return {
      wordCloud: "",
      wordCloudKeywords: {},
      wordData: [],
    };
  },
  watch: {
    apiDataLineChart: {
      handler(newVal, oldVal) {
        console.log("wordcloud", newVal)
        this.wordData = newVal
        this.setOriginWordCloud()
        this.setWordCloud()
      }
    }
  },
  methods: {
    setOriginWordCloud() {
      // echarts.init(this.$refs.wordCloud).dispose();
      // this.wordCloud = echarts.init(this.$refs.wordCloud);
      this.wordCloud = new js2wordcloud(this.$refs.wordCloud);
    },
    setWordCloud() {
      let option = {
        list: this.wordData,
        shape: "diamond",
        minFontSize: 12,
        maxFontSize: 36,
        color: function () {
          let a = ["#6C8B22", "#24678B"];
          let n = Math.random();
          if (n > 0.5) {
            return a[0];
          } else {
            return a[1];
          }
        },
        ellipticity: 1,
        backgroundColor: "rgba(0,0,0,0)",
      };
      this.wordCloud.setOption(option);
    },
  },
  mounted() {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.newsKeywordCloud {
  width: 35%;
  height: 215px;
  margin-left: 7%;
  margin-top: 10px;
  .word-cloud {
    width: 95%;
    height: 100%;
  }
}

</style>
